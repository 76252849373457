<div class="h-100 overflow-scroll">
  <div class="d-flex flex-1 flex-column justify-content-start align-items-center py-5 mb-5">
    <div class="card w-100" style="max-width: 480px">
      <div class="card-body">
        <div *ngIf="(user$ | async) as user">
          <h4 class="card-title font-serif">Account Settings</h4>

          <div *ngIf="(errorMessage$ | async) as errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
          <div *ngIf="(statsMessage$ | async) as statusMessage" class="alert alert-success">{{ statusMessage }}</div>
          
          <h5 class="font-serif">Your Profile</h5>

          <form [formGroup]="userDataForm" (ngSubmit)="updateUserData()" novalidate>
            <div class="form-group">
              <label for="nameInput" >Name</label>
              <input
                id="nameInput"
                class="form-control"
                type="text"
                formControlName="name"
                placeholder="Display Name"
                [ngClass]="{ 'is-invalid': name.touched && name.invalid }"
                autocomplete="name"
              />
              <div class="invalid-feedback">
                <div *ngIf="name.hasError('required')" >Name is required.</div>
              </div>
            </div>

            <div class="form-group">
              <label for="emailInput" >Email address</label>
              <input
                id="emailInput"
                class="form-control"
                type="email"
                formControlName="email"
                placeholder="Email"
                [ngClass]="{ 'is-invalid': email.touched && email.invalid }"
                autocomplete="email"
              />

              <div class="invalid-feedback">
                <div *ngIf="email.getError('required')" >Email is required.</div>
                <div *ngIf="email.getError('email')" >Must be a valid email</div>
              </div>
            </div>

            <button
              class="btn btn-primary w-100"
              [disabled]="!userDataForm.dirty || !userDataForm.valid || (isLoading$ | async)"
              type="submit"
              
            >
              Save changes
            </button>
          </form>

          <br />
          <hr />

          <h5 class="font-serif">Change Your Password</h5>

          <form [formGroup]="userPasswordForm" (ngSubmit)="changePassword()" novalidate>
            <div class="form-group">
              <label for="oldPasswordInput" >Current Password</label>
              <input
                id="oldPasswordInput"
                class="form-control"
                type="password"
                formControlName="oldPassword"
                placeholder="Your current password"
                [ngClass]="{ 'is-invalid': oldPassword.touched && oldPassword.invalid }"
                autocomplete="off"
              />

              <div class="invalid-feedback">
                <div *ngIf="oldPassword.getError('required')" >Password is required.</div>
                <div *ngIf="oldPassword.getError('minlength')" >Password must be at least 8 characters.</div>
              </div>
            </div>

            <div class="form-group">
              <label for="newPasswordInput" >New Password</label>
              <input
                id="newPasswordInput"
                class="form-control"
                type="password"
                formControlName="newPassword"
                placeholder="Your new password"
                [ngClass]="{ 'is-invalid': newPassword.touched && newPassword.invalid }"
                autocomplete="off"
              />

              <div class="invalid-feedback">
                <div *ngIf="newPassword.getError('required')" >Password is required.</div>
                <div *ngIf="newPassword.getError('minlength')" >Password must be at least 8 characters.</div>
              </div>
            </div>

            <button
              class="btn btn-primary w-100"
              [disabled]="!userPasswordForm.dirty || !userPasswordForm.valid || (isLoading$ | async)"
              
              type="submit"
            >
              Change password
            </button>
          </form>

          <br />

          <hr />

          <button
            class="btn btn-danger btn-sm float-right"
            [disabled]="isLoading$ | async"
            (click)="deleteAccount(user)"
            
          >
            Permanently delete my account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
