<div class="d-flex flex-column justify-content-center align-items-center h-100">
  <app-logo class="mb-4"></app-logo>
  <div class="card" style="width: 380px">
    <div class="card-body">
      <div *ngIf="(errorMessage$ | async) as errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
      <div *ngIf="(statusMessage$ | async) as statusMessage" class="alert alert-info">{{ statusMessage }}</div>

      <h4 class="card-title font-serif" >Reset your password</h4>

      <p class="card-text" >Please enter your new password.</p>

      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" novalidate>

        <div class="form-group">
          <label for="emailInput" >Email Address</label>
          <input id="emailInput" class="form-control" type="email" formControlName="email" placeholder="Email" readonly autocomplete="email">
        </div>

        <div class="form-group">
          <label for="passwordInput" >Password</label>
          <input id="passwordInput" class="form-control" type="password" formControlName="newPassword" placeholder="New Password" [ngClass]="{'is-invalid': newPassword.touched && newPassword.invalid}" autocomplete="new-password">

          <div class="invalid-feedback">
            <div *ngIf="newPassword.getError('required')" >Password is required.</div>
            <div *ngIf="newPassword.getError('minlength')" >Password must be at least {{passwordMinLength}} characters long.</div>
          </div>
        </div>

        <button type="submit" [disabled]="!resetPasswordForm.valid || (isLoading$ | async) || (statusMessage$ | async)" class="btn btn-primary w-100" >Reset password</button>
      </form>
    </div>
  </div>
  <div class="text-center mt-3">
    <a class="text-sm btn btn-link" routerLink="/login">Back to sign in</a>
  </div>
</div>