<div class="d-flex flex-column justify-content-center align-items-center h-100">
  <app-logo class="mb-4"></app-logo>
  <div class="card" style="width: 380px">
    <div class="card-body">
      <div *ngIf="(errorMessage$ | async) as errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
      <div *ngIf="(statusMessage$ | async) as statusMessage" class="alert alert-info">{{ statusMessage }}</div>

      <h4 class="card-title font-serif" >Forgot your password?</h4>

      <p class="card-text" >Enter your account's email address, we'll send you a link to reset your password.</p>

      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" novalidate>

        <div class="form-group">
          <label for="emailInput" >Email Address</label>
          <input id="emailInput" class="form-control" type="email" formControlName="email" placeholder="Email" [ngClass]="{'is-invalid': email.touched && email.invalid}" autocomplete="email">

          <div class="invalid-feedback">
            <div *ngIf="email.getError('required')" >Email is required.</div>
            <div *ngIf="email.getError('email')" >Must be a valid email</div>
          </div>
        </div>

        <button type="submit" [disabled]="!forgotPasswordForm.valid || (isLoading$ | async)" class="btn btn-primary w-100" >Send password reset link</button>
      </form>
    </div>
  </div>
  <div class="mt-3 text-center">
    <a class="btn btn-link text-sm" routerLink="/login" >Back to sign in</a>
  </div>
</div>