<div class="d-flex flex-column justify-content-center align-items-center h-100">
  <app-logo class="mb-4"></app-logo>
  <div class="card" style="width: 380px">
    <div class="card-body">
      <div *ngIf="(errorMessage$ | async) as errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

      <div *ngIf="inviteOnly" class="alert alert-warning">Traduora is currently invite-only.</div>

      <h4 class="card-title font-serif" >Create an account</h4>

      <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-group">
          <label for="nameInput" >Name</label>
          <input
            [disabled]="inviteOnly"
            id="nameInput"
            class="form-control"
            type="text"
            formControlName="name"
            placeholder="Display Name"
            [ngClass]="{ 'is-invalid': name.touched && name.invalid }"
            autocomplete="name"
          />
          <div class="invalid-feedback">
            <div *ngIf="name.hasError('required')" >Name is required.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="emailInput" >Email Address</label>
          <input
            [disabled]="inviteOnly"
            id="emailInput"
            class="form-control"
            type="email"
            formControlName="email"
            placeholder="Email"
            [ngClass]="{ 'is-invalid': email.touched && email.invalid }"
            autocomplete="email"
          />
          <div class="invalid-feedback">
            <div *ngIf="email.hasError('required')" >Email is required.</div>
            <div *ngIf="email.hasError('email')" >Must be a valid email</div>
          </div>
        </div>

        <div class="form-group">
          <label for="newPasswordInput" >Password</label>
          <input
            [disabled]="inviteOnly"
            id="newPasswordInput"
            class="form-control"
            type="password"
            formControlName="password"
            placeholder="Create a password"
            [ngClass]="{ 'is-invalid': password.touched && password.invalid }"
            autocomplete="off"
          />
          <div class="invalid-feedback">
            <div *ngIf="password.hasError('required')" >Password is required.</div>
            <div *ngIf="password.hasError('minlength')" >Password must be at least {{ passwordMinLength }} characters long.</div>
          </div>
        </div>

        <button type="submit" [disabled]="!signupForm.valid || (isLoading$ | async)" class="btn btn-primary w-100" >Get started</button>
      </form>

      <div class="text-center border-top mt-5">
        <div class="mt-3" *ngFor="let provider of (providers$ | async)">
          <app-sign-in-with [provider]="provider"></app-sign-in-with>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-3">
    <p class="text-sm">Already have an account? <a class="btn btn-link text-sm p-0" routerLink="/login" >Sign in </a></p>
  </div>
</div>
